<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row class="pa-0 mt-0 mb-0">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 ">
                    <div style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                        <hr class="vr"/>
                        <p class="tblName pb-2">Afiliación IDSE</p>
                    </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 d-flex justify-end">
                    <v-btn
                        color="transparent" 
                        @click="generarReporte('xlsx')"
                        rounded
                        class="btnReportes v-btn-excel elevation-0 pa-0"
                        style="min-width: 10px;"
                        :ripple="true"
                    >
                        <img src="/static/icon/xls.png" style="width:28px" />
                    </v-btn>
                    <v-btn
                        elevation="0"
                        class="btnNormalClaro ml-3"
                        @click="() => {}"
                    >
                        Validacion
                    </v-btn>
                    <v-btn
                        elevation="0"
                        class="btnNormalClaro ml-3"
                        @click="() => {}"
                    >
                        Enviar IMSS
                    </v-btn>
                    <BtnAcciones
                        :btnNombre="'Acciones'"
                        :btnColor="'color-58C5D8'"
                        class="ml-3"
                    >
                        <template v-slot:opciones>
                            <div
                                @click="!masivo ? '#':exportar('txt')" 
                                :style="!masivo? 'cursor:not-allowed;':''" 
                                class="btnAccionesItem py-2"
                                :class="[!masivo ? 'deshabilitado':'']"
                            >
                                <span>Formato .txt para IDSE</span>
                            </div>
                            <div 
                                @click="!masivo ? '#':exportar('pdf')" 
                                :style="!masivo? 'cursor:not-allowed;':''" 
                                class="btnAccionesItem py-2"
                                :class="[!masivo ? 'deshabilitado':'']"
                            >
                                <span>Exportar PDF</span>
                            </div>
                            <div 
                                @click="!masivo ? '#':exportar('sua')" 
                                :style="!masivo? 'cursor:not-allowed;':''" 
                                class="btnAccionesItem py-2"
                                :class="[!masivo ? 'deshabilitado':'']"
                            >
                                <span>Exportar SUA</span>
                            </div>
                            
                        </template>
                    </BtnAcciones>
                    <v-btn
                        height="40"
                        width="40"
                        fab
                        color="#FFFFFF"
                        class="elevation-0 mt-0 mr-3"
                        @click="abrirModalBuscar()"
                    >
                        <v-icon color="#96999A">refresh</v-icon>
                    </v-btn>
                    <v-btn
                        height="40"
                        width="40"
                        fab
                        color="#FFFFFF"
                        class="elevation-0 mt-0"
                        @click="abrirModalBuscar()"
                    >
                        <v-icon color="#96999A">mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <v-col cols="12" xs="12" sm="12" md="12" class="div-card py-8">
                    <div>
                        <v-row class="py-0 my-0 mx-2">
                            <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsRegistrosPatronales"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Registro patronal"
                                    persistent-hint
                                    v-model="registro_patronal_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    label="Fecha inicial"
                                    :valorDefecto="fecha_inicial"
                                    v-model="fecha_inicial"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicial"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    label="Fecha final"
                                    :valorDefecto="fecha_final"
                                    v-model="fecha_final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsEstatus"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Estatus de aviso"
                                    persistent-hint
                                    v-model="estatus"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-divider class="mx-6"></v-divider>

                        <v-row class="py-0 my-0 mt-5 mx-6">
                            <div 
                                class="btn-afiliacion mr-2 d-flex justify-center align-center" 
                                :class="btnAfiliacion == 'btn1' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroAfiliacion('btn1')"
                            >
                                <span class="ma-3">Reingresos</span>
                            </div>
                            <div 
                                class="btn-afiliacion mr-2 d-flex justify-center align-center" 
                                :class="btnAfiliacion == 'btn2' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroAfiliacion('btn2')"
                            >
                                <span class="ma-3">Modificación salarios</span>
                            </div>
                            <div 
                                class="btn-afiliacion mr-2 d-flex justify-center align-center" 
                                :class="btnAfiliacion == 'btn3' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroAfiliacion('btn3')"
                            >
                                <span class="ma-3">Bajas</span>
                            </div>
                            <div 
                                class="btn-afiliacion mr-2 d-flex justify-center align-center" 
                                :class="btnAfiliacion == 'btn4' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroAfiliacion('btn4')"
                            >
                                <span class="ma-3">Todos</span>
                            </div>
                        </v-row>

                        <data-table
                            class="mt-0 mx-5"
                            ref="tablaAfiliacion"
                            :url="url"
                            :columns="columns"
                            :filters="filters"
                            :showAdvancedFilters="false"
                            :perPage="[10,25,50,100]"
                            :idTabla="'tablaAfiliacion'"
                            :classPagination="'box-shadow-none'"
                        >
                            <tbody slot="body" slot-scope="{ data }" class="tablaBody">
                                <tr :key="item.id" v-for="item in dataFake">
                                    <td><div>{{item.registro_patronal}}</div></td>
                                    <td><div>{{item.clave_empleado}}</div></td>
                                    <td><div>{{item.nombre}}</div></td>
                                    <td><div>{{item.apellido_paterno}}</div></td>
                                    <td><div>{{item.apellido_materno}}</div></td>
                                    <td><div>{{item.nss}}</div></td>
                                    <td><div>{{item.sbc}}</div></td>
                                    <td><div>{{item.fecha_movimiento}}</div></td>
                                    <td><div>{{item.curp}}</div></td>
                                    <td><div>{{item.UMF}}</div></td>
                                    <td><div>{{item.tipo_trabajador}}</div></td>
                                    <td><div>{{item.tipo_salario}}</div></td>
                                    <td><div>{{item.jornada_reducida}}</div></td>
                                    <td><div>{{item.estado}}</div></td>
                                    <td><div>{{item.fecha_envio}}</div></td>
                                    <td><div>{{item.acuse_recibido}}</div></td>
                                    <td><div>{{item.acuse_operado}}</div></td>
                                    <td><div>{{item.acuse_rechazo}}</div></td>
                                    <td><div>{{item.causa_rechazo}}</div></td>
                                </tr>
                            </tbody>
                        </data-table>
                    </div>
                </v-col>
            </v-row>
        </v-container>

       
    </div>
</template>

<script>
import Datatable from '@/components/datatable/Datatable.vue';
import BtnAcciones from '@/components/BtnAcciones.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import Notify from '@/plugins/notify';
import datos from '../../js/datos.js'

export default {
    components: {
        'data-table': Datatable,
        BtnAcciones,
        VuetifyDatePicker,
    },
    data() {
        return {
            url         : "catalogo-sat/clases/find",
            columns     : [
                {
                    label: 'Registro Patronal',
                    name: 'registro_patronal',
                    filterable: true
                },
                {
                    label: 'Clave empleado',
                    name: 'clave_empleado',
                    filterable: true
                },
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Apellido paterno',
                    name: 'apellido_paterno',
                    filterable: false
                },
                {
                    label: 'Apellido materno',
                    name: 'apellido_materno',
                    filterable: false
                },
                {
                    label: 'NSS',
                    name: 'nss',
                    filterable: false
                },
                {
                    label: 'SBC',
                    name: 'sbc',
                    filterable: false
                },
                {
                    label: 'Fecha de movimiento',
                    name: 'fecha_movimiento',
                    filterable: false
                },
                {
                    label: 'CURP',
                    name: 'curp',
                    filterable: false
                },
                {
                    label: 'UMF',
                    name: 'umf',
                    filterable: false
                },
                {
                    label: 'Tipo trabajador',
                    name: 'tipo_trabajador',
                    filterable: false
                },
                {
                    label: 'Tipo salario',
                    name: 'tipo_salario',
                    filterable: false
                },
                {
                    label: 'Jornada reducida',
                    name: 'jornada_reducida',
                    filterable: false
                },
                {
                    label: 'Estado',
                    name: 'estado',
                    filterable: false
                },
                {
                    label: 'Fecha de envío',
                    name: 'fecha_envio',
                    filterable: false
                },
                {
                    label: 'Acuse recibido',
                    name: 'acuse_recibido',
                    filterable: false
                },
                {
                    label: 'Acuse operado',
                    name: 'acuse_operado',
                    filterable: false
                },
                {
                    label: 'Acuse de rechazo',
                    name: 'acuse_rechazo',
                    filterable: false
                },
                {
                    label: 'Causa de rechazo',
                    name: 'causa_rechazo',
                    filterable: false
                },
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc"
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            imagen          : "",
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            masivo          : false,
            dataFake        : [],
            btnAfiliacion   : 'btn1',

            itemsRegistrosPatronales: [],
            registro_patronal_value : null,
            fecha_inicial           : null,
            fecha_final             : null,
            itemsEstatus            : [],
            estatus                 : null,
        }
    },
    methods: {
        exportar(tipo = 'txt'){

        },
        generarReporte(tipo = 'xlsx'){

        },
        abrirModalBuscar(){

        },
        filtroAfiliacion(btn = 'btn1'){
            this.btnAfiliacion = btn;
        }
    },
    
    mounted(){
        this.dataFake = datos
        if(document.querySelector('#tablaAfiliacion')){
            document.querySelector('.emptyTable').style.width     = "6000px";
            document.querySelector('.emptyTable').style.minWidth   = "6000px";
            //document.querySelector('.tablaBody tr td').style.width     = "300px";
        }
    },
    updated(){
        if(document.querySelector('#tablaAfiliacion')){
            document.querySelector('.emptyTable').style.width     = "6000px";
            document.querySelector('.emptyTable').style.minWidth   = "6000px";
        }
        if(document.querySelector('#tablaAfiliacion')){
            document.querySelector('.tablaBody').style.maxHeight    = "none";
            document.querySelector('.tablaBody').style.width     = "6700px";
            document.querySelector('.tablaBody').style.minWidth   = "6000px";
            document.querySelector('.tablaBody').style.background   = "#fff";
            //document.querySelector('.tablaBody tr td').style.width     = "300px";
        }
    }
}
</script>

<style scoped>
    .tablaBody{
        width: 5700px;
        max-height: none;
        min-width: 5000px;
    }
    .div-card {
        background-color: #fdfcfc;
        border-radius: 20px;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .deshabilitado{
        background:#fcf6f6;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.deshabilitado:hover{
        background:#f2f2f2;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.deshabilitado span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    .v-btn-excel::before {
        background-color: transparent;
    }
    .btn-afiliacion {
        background-color: #E4E4E5;
        height: 2.1875rem;
        border-radius: 8px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
    }
    .btn-activo {
        color: #444975;
    }
    .btn-inactivo {
        color: #828282;
    }
    

</style>
