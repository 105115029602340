
export default [
    {
        registro_patronal: 'Xenon SA de CD',
        clave_empleado: '001',
        nombre: 'CLAUDIA DANIELA',
        apellido_paterno: 'ACOSTA',
        apellido_materno: 'RIVAS',
        nss: '16794823590',
        sbc: '375.50',
        fecha_movimiento:'01/01/2024',
        curp:'AORC970903MQRCVL00',
        UMF: "42",
        tipo_trabajador: 'Permanente',
        tipo_salario: 'Mixto',
        jornada_reducida: 'Semanal normal',
        estado: 'Aceptado',
        fecha_envio: '02/02/2024',
        acuse_recibido: '197346280101',
        acuse_operado: '---',
        acuse_rechazo: '---',
        causa_rechazo: '---'
    },
    {
        registro_patronal: 'Xenon SA de CD',
        clave_empleado: '001',
        nombre: 'JOSUE ENMANUEL',
        apellido_paterno: 'ARGAEZ',
        apellido_materno: 'CRUZ',
        nss: '82138915671',
        sbc: '390.50',
        fecha_movimiento:'01/01/2024',
        curp:'AACJ890412HYNRRS04',
        UMF: "42",
        tipo_trabajador: 'Permanente',
        tipo_salario: 'Mixto',
        jornada_reducida: 'Semanal normal',
        estado: 'Aceptado',
        fecha_envio: '02/02/2024',
        acuse_recibido: '197346280102',
        acuse_operado: '---',
        acuse_rechazo: '---',
        causa_rechazo: '---'
    }
]